import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPageContainer as SourceProductPageContainer,
} from 'SourceRoute/ProductPage/ProductPage.container';
import { ProductPageComponentProps } from 'SourceRoute/ProductPage/ProductPage.type';
import { getLocalStorageItemToken } from 'Util/Common/Util';
import history from 'Util/History';
import { RootState } from 'Util/Store/Store.type';

import { ProductPageContainerComponentPropKeys } from './ProductPage.type';
/** @namespace Pwa/Route/ProductPage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Route/ProductPage/Container */
export class ProductPageContainer extends SourceProductPageContainer {
    containerProps(): Pick<ProductPageComponentProps, ProductPageContainerComponentPropKeys> {
        const { isMobile, areReviewsEnabled } = this.props;
        const { parameters, activeProduct: newActiveProduct } = this.state;
        const { location } = history;

        return {
            areDetailsLoaded: this.getAreDetailsLoaded(),
            isAttributesTabEmpty: this.isProductAttributesTabEmpty(),
            isInformationTabEmpty: this.isProductInformationTabEmpty(),
            activeProduct: this.getActiveProductDataSource(),
            // @ts-ignore
            newActiveProduct: newActiveProduct ?? {},
            dataSource: this.getDataSource(),
            useEmptyGallerySwitcher: this.getUseEmptyGallerySwitcher(),
            isVariant: this.getIsVariant(),
            isMobile,
            parameters,
            location,
            areReviewsEnabled,
        };
    }

    requestProduct(): void {
        const { requestProduct, productSKU } = this.props;
        const { currentProductSKU } = this.state;

        /**
         * If URL rewrite was not passed - do not request the product.
         */
        if (!productSKU) {
            return;
        }

        /**
         * Skip loading the same product SKU the second time
         */
        if (currentProductSKU === productSKU) {
            return;
        }

        this.setState({ currentProductSKU: productSKU });

        const isMphUser = getLocalStorageItemToken('is_mph');

        const options = {
            isSingleProduct: true,
            args: {
                filter: {
                    ...this.getProductRequestFilter(),
                    customFiltersIsMph: isMphUser,
                },
            },
        };

        requestProduct(options);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer);
