// @ts-nocheck
/* eslint-disable react/forbid-elements */
/* eslint-disable react/forbid-dom-props */
/* eslint-disable fp/no-let */
/* eslint-disable no-magic-numbers */
import If from 'Component/If';
import ProductGallery from 'Component/ProductGallery';
import TextPlaceholder from 'Component/TextPlaceholder';
import UploadIcon2 from 'Component/UploadIcon2/UploadIcon2';
import { ProductPageTabs } from 'Route/ProductPage/ProductPage.config';
import {
    ProductPageComponent as SourceProductPageComponent,
} from 'SourceRoute/ProductPage/ProductPage.component';
import { ProductPageTab } from 'SourceRoute/ProductPage/ProductPage.type';
import { ReactElement } from 'Type/Common.type';
import { getAttributesWithValues } from 'Util/Product';
import { getProductInStock } from 'Util/Product/Extract';
import { IndexedAttributeWithValue, StockCheckProduct } from 'Util/Product/Product.type';

import './ProductPage.override.style';

/** @namespace Pwa/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPageComponent {
    tabMap: Record<ProductPageTabs, ProductPageTab> = {
        [ProductPageTabs.INFORMATION]: {
            name: __('About'),
            shouldTabRender: (): boolean => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: (key: string): ReactElement => this.renderProductInformationTab(key),
        },
        [ProductPageTabs.ATTRIBUTES]: {
            name: __('Details'),
            shouldTabRender: (): boolean => {
                const { isAttributesTabEmpty } = this.props;

                return !isAttributesTabEmpty;
            },
            render: (key: string): ReactElement => this.renderProductAttributesTab(key),
        },
        [ProductPageTabs.REVIEWS]: {
            name: __('Reviews'),
            // Return false since we never show 'Add review' button
            shouldTabRender: (): boolean => false,
            render: (key: string): ReactElement => this.renderProductReviewsTab(key),
        },
    };

    className = 'ProductActions';

    renderSkuAndStock(): ReactElement {
        return (
            <div block="ProductActions">
                <div block="ProductActions" elem="PriceStock">
                    <div
                      block="ProductActions"
                      elem="Schema"
                    >
                        { this.renderSku() }

                    </div>
                    { this.renderStock() }
                </div>
            </div>
        );
    }

    renderProductBrand(): ReactElement | null {
        const {
            dataSource: {
                attributes: {
                    sub_family: { attribute_options = {}, attribute_value = '' } = {},
                } = {},
            },
        } = this.props;

        const subFamilyLabel = attribute_options?.[attribute_value]?.label;

        if (!subFamilyLabel) {
            return null;
        }

        return (
            <>
                <meta itemProp="brand" content={ subFamilyLabel } />
                <h4 block={ this.className } elem="Brand" itemProp="brand">
                    <TextPlaceholder content={ subFamilyLabel } />
                </h4>
            </>
        );
    }

    renderStock(): ReactElement {
        const { activeProduct } = this.props;

        const inStock = getProductInStock(activeProduct as Partial<StockCheckProduct>);

        if (inStock) {
            return <span block={ this.className } elem="Stock" mods={ { inStock } }>{ __('In stock') }</span>;
        }

        return <span block={ this.className } elem="Stock">{ __('Out of stock') }</span>;
    }

    renderSku(): ReactElement {
        const { activeProduct } = this.props;
        const { sku, type_id } = activeProduct;

        return (
            <If condition={ type_id !== 'configurable' }>
                <span block={ this.className } elem="Sku" itemProp="sku">
                    <b>{ __('Cart.code') }</b>
                    { ' ' }
                    { sku }
                </span>
            </If>
        );
    }

    renderPDFFiles(pdfFiles: IndexedAttributeWithValue | undefined): ReactElement | null {
        const attributeOptions = pdfFiles?.attribute_value;

        if (!attributeOptions) {
            return null;
        }

        const files = JSON.parse(pdfFiles?.attribute_value || '');

        return files?.items?.length > 0 && (
            <div
              block="ProductAttributes"
              elem="AttributeBlock"
              className="attachment_data"
              key={ pdfFiles?.attribute_code }
            >
                <p>{ __('ProductAttributes.pdfTitle') }</p>
                { files.items.map((item: any) => item.file.map((file: any) => (
                    <div className="attachment_data">
                        <span>
                            { file.name }
                        </span>
                        { '  ' }
                        <a href={ file.url } target="_blank" rel="noopener noreferrer">
                            { __('ProductAttributes.pdfDownload') }
                            <UploadIcon2 />
                        </a>
                    </div>
                ))) }
            </div>
        );
    }

    renderProductPageContent(): ReactElement {
        const {
            areDetailsLoaded,
            activeProduct,
            newActiveProduct,
            useEmptyGallerySwitcher,
            isVariant,
            isMobile,
        } = this.props;

        const attributesWithValues = getAttributesWithValues(activeProduct);

        const pdfFiles = Object.values(attributesWithValues).find(
            (attribute) => attribute.attribute_code === 'attachment_data',
        );

        const price_discount_percent = Object.keys(newActiveProduct).length > 0
            ? newActiveProduct.price_discount_percent
            : activeProduct.price_discount_percent;

        return (
            <>
                <If condition={ isMobile }>
                    { this.renderProductDesktopMainData() }
                    { this.renderSkuAndStock() }
                </If>
                <ProductGallery
                  product={ activeProduct }
                  areDetailsLoaded={ areDetailsLoaded }
                    // @ts-ignore
                  discountPercent={ price_discount_percent }
                  isWithEmptySwitcher={ useEmptyGallerySwitcher }
                  showLoader={ isVariant }
                />
                { this.renderProductActions() }
                { this.renderPDFFiles(pdfFiles) }
            </>
        );
    }
}

export default ProductPageComponent;
